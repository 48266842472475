import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { updateStatus } from "../api";
import { TypographySubHeading } from "../styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import LoadAusPostLabel from "./LoadAusPostLabel";
import TrackEvents from "../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  dialogBoxPadding: {
    padding: "10px 25px !important",
  },
}));

const ConfirmedStatusPopup = (props) => {
  const classes = useStyles();
  const {
    id,
    status,
    handleClose,
    open,
    setStatus,
    changedorderstatus,
    setChangedOrderStatus,
    row,
  } = props;
  const [loading, setLoading] = useState(false);
  const [trackingid, setTrackingid] = useState("");
  const [checkMail, setCheckMail] = useState(true);
  const [checkSms, setCheckSms] = useState(false);
  const [error, setError] = useState("");
  const [fullfilllocation, setFullFillLocation] = useState("");
  const [openLabel, setOpenLabel] = React.useState(false);

  const [ordertrackingmethod, setOrderTrackingMethod] =
    useState("collectfromoffice");
  const userid = localStorage.getItem("auth_id");

  //console.log(row);
  const handleOrderTrackingMethodChange = (event) => {
    setOrderTrackingMethod(event.target.value);
    if (event.target.value === "ausposteparcel") {
      // setOpenLabel(true);
      window
        .open(
          `https://printyocrm.printyo.net.au/auspostlabel?orderid=${id}`,
          "_blank"
        )
        .focus();
    }
  };

  const handlefullfilllocationOnChange = (event) => {
    setFullFillLocation(event.target.value);
  };
  const handleCloseLabel = (value) => {
    setOpenLabel(false);
  };

  function barcodeAutoFocus() {
    document.getElementById("SearchbyScanning").focus();
  }

  function onChangeBarcode(event) {
    setTrackingid(event.target.value);
  }

  function onKeyPressBarcode(event) {
    if (event.keyCode === 13) {
      setTrackingid(event.target.value);
    }
  }
  const handleChange = (e) => {
    setTrackingid(e.target.value);
  };

  const handleMailChange = () => {
    setCheckMail(!checkMail);
  };

  const handleSmsChange = () => {
    setCheckSms(!checkSms);
  };

  const handleConfirmed = async () => {
    //console.log(status, id);
    setError("");
    if (
      status === "dispatch" &&
      ordertrackingmethod !== "collectfromoffice" &&
      !trackingid
    ) {
      console.log(ordertrackingmethod);
      setError("Please type tracking number while submitting the form");
      return;
    }
    if (status === "sendtoprint" && !fullfilllocation) {
      setError("Please select fullfill location");
      return;
    }
    try {
      setLoading(true);
      const data = new FormData();
      data.append("userid", userid);
      data.append("orderstatus", status);
      data.append("trackingid", trackingid);
      data.append("ordertrackingmethod", ordertrackingmethod);
      data.append("sendmail", checkMail);
      data.append("sendsms", checkSms);
      data.append("fullfilllocation", fullfilllocation);
      if (status === "dispatch") {
        data.append("name", row.billing.first_name);
        data.append("customer_id", row.billing.customer_id);
        data.append("email", row.billing.email);
      }
      const res = await updateStatus(id, data);
      if (res.status === 200) {
        setLoading(false);
      } else {
        alert("Opps something went wrong");
        setLoading(false);
      }
      handleClose();
      setStatus("");
      console.log(status, "from ccomfirmedpopup");
      setChangedOrderStatus(status);
      if (trackingid && ordertrackingmethod) {
        TrackEvents(`${id}`, {
          process: `Order Status Changed To ${status} with tracking Id ${trackingid} with send mail ${checkMail} and send sms ${checkSms} and by method of ${ordertrackingmethod}`,
        });
      } else if (ordertrackingmethod) {
        TrackEvents(`${id}`, {
          process: `Order Status Changed To ${status} with send mail ${checkMail} and send sms ${checkSms}  and by method of ${ordertrackingmethod}`,
        });
      } else {
        TrackEvents(`${id}`, {
          process: `Order Status Changed To ${status}`,
        });
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleClose();
      setStatus("");
      setError("");
    }
  };

  return (
    <>
      <LoadAusPostLabel
        openLabel={openLabel}
        handleCloseLabel={handleCloseLabel}
        orderid={id}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.dialogBoxPadding}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <TypographySubHeading sx={{ color: "red" }}>
            {error}
          </TypographySubHeading>
          <Typography>
            Do you want to change the status of the order # {id} to {status}{" "}
          </Typography>
          {status === "sendtoprint" ? (
            <>
              <Typography>Is the mock up approved?</Typography>
              <Typography>Have you uploaded the print file?</Typography>
              <DialogContent>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    FullFillment Center
                  </InputLabel>
                  <Select
                    labelId="simple-select-fulllocation"
                    id="simple-select-fulllocation"
                    value={fullfilllocation}
                    label="FullFill Location"
                    onChange={handlefullfilllocationOnChange}
                  >
                    <MenuItem value={"Printyo Thomastown"}>
                      Printyo Thomastown
                    </MenuItem>
                    <MenuItem value={"Printyo Altona"}>Printyo Altona</MenuItem>
                    <MenuItem value={"Printyo Sydney"}>Printyo Sydney</MenuItem>
                    <MenuItem value={"Printyo Heidelberg"}>
                      Printyo Heidelberg
                    </MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
            </>
          ) : null}
        </DialogContent>
        {status === "dispatch" ? (
          <>
            <DialogContent className={classes.dialogBoxPadding}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Shipping Method
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={ordertrackingmethod}
                  label="Shipping Method"
                  onChange={handleOrderTrackingMethodChange}
                >
                  <MenuItem value={"collectfromoffice"}>
                    Collect From Office
                  </MenuItem>
                  <MenuItem value={"auspost"}>Australian Post</MenuItem>
                  <MenuItem value={"ausposteparcel"}>
                    Australian Post E-Parcel
                  </MenuItem>
                  <MenuItem value={"tnt"}>TNT</MenuItem>
                  <MenuItem value={"uber"}>Uber</MenuItem>
                  <MenuItem value={"civic"}>Civic</MenuItem>
                  <MenuItem value={"others"}>Others</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            {ordertrackingmethod !== "collectfromoffice" ? (
              <DialogContent className={classes.dialogBoxPadding}>
                <TypographySubHeading>
                  Please enter the tracking number for #{id}{" "}
                </TypographySubHeading>
                <TextField
                  type="text"
                  value={trackingid}
                  onChange={handleChange}
                  rows={2}
                  size="small"
                  sx={{ mt: 2 }}
                  fullWidth
                  autoFocus={true}
                  id="SearchbyScanning"
                  className="SearchInput"
                  onKeyDown={onKeyPressBarcode}
                  onBlur={barcodeAutoFocus}
                />
              </DialogContent>
            ) : null}

            <DialogContent>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox checked={checkMail} onChange={handleMailChange} />
                  }
                  label="Send mail to customer"
                />
              </Box>
              <Box mb={1}>
                <FormControlLabel
                  control={
                    <Checkbox checked={checkSms} onChange={handleSmsChange} />
                  }
                  label="Send sms to customer"
                />
              </Box>
            </DialogContent>
          </>
        ) : null}
        <DialogActions>
          <Button
            onClick={handleConfirmed}
            variant="contained"
            size="small"
            color="primary"
            disabled={loading}
          >
            {loading ? "Processing" : "Confirm"}
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            size="small"
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmedStatusPopup;
